/* eslint-disable import/prefer-default-export */
export const getAlign = (align?: string) => {
  switch (align) {
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "center":
      return "center";
    case "baseline":
      return "baseline";
    default:
      return "unset";
  }
};
export const getJustify = (align?: string) => {
  switch (align) {
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "center":
      return "center";
    case "between":
      return "space-between";
    default:
      return "unset";
  }
};